export const contact = [
  {
    title: "Resume",
    url: "https://docs.google.com/document/d/e/2PACX-1vRQUuHXzhgZ9HJ8yaKRVI8IUWpDL9Px8Kie-9ttAVL_ovy_cXgUKSrlmHKbzktNxA/pub",
  },
  {
    title: "Linkedin",
    url: "https://www.linkedin.com/in/ryan-haefner-259432188/",
  },
  {
    title: "Contact Me",
    url: null,
  },
];
